<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل المصروف </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined v-model="student.amount" type="number" label="المبلغ"></v-text-field>

              <v-textarea rounded rows="3" row-height="15" outlined v-model="student.notes" name="input-7-4" label="الملاحظات"></v-textarea>
              <!-- <v-autocomplete rounded v-model="gender" :items="$store.state.genders" item-text="name" item-value="id" outlined label="جنس الطالب"></v-autocomplete>
  
                <v-dialog ref="dialogDate" v-model="showDate" :return-value.sync="date" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field outlined rounded v-model="date" label="المواليد" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="$refs.dialogDate.save(date)"> موافق </v-btn>
                    <v-btn text color="primary" @click="showDate = false"> الغاء </v-btn>
                  </v-date-picker>
                </v-dialog> -->
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> تعديل </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="info" @click="show = true" v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
      </template>
      <span>تعديل طالب</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    student: Object,
  },
  data: () => ({
    show: false,
    overlay: false,
    studentName: "",
    studentPhone: "",
    studentImage: "",
    gender: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    showDate: false,
  }),
  methods: {
    saveData() {
      let self = this;

      let expenseDate = new Date(self.student.createdAtFormat);
      self.student.createdAtFormat = `${expenseDate.getFullYear()}-${expenseDate.getMonth() + 1}-${expenseDate.getDate()}`;

      self.$http
        .put(
          `${self.$store.state.apiUrl}/trainingExpenses/${self.student.idTrainingExpenses}`,
          {
            amount: self.student.amount,
            notes: self.student.notes,
            trainingCenterId: self.student.trainingCenterId,
            createdBy: self.student.createdBy,
            createdAt: self.student.createdAtFormat,
          },
          {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }
        )
        .then((res) => {
          console.log(res.data);

          this.$toasted.success("تم تعديل البيانات");
          this.$emit("reloadTask");
        });

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
