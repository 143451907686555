<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="info" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>بحث </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-autocomplete v-model="doctorId" :items="doctors" item-text="userName" item-value="idUser" outlined label="اسم الطبيب"></v-autocomplete>
              <v-dialog ref="dialog" v-model="datePickerSearchModel" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateRangeText" label="اختر التاريخ من - الى" readonly outlined v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="dates" scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="datePickerSearchModel = false"> الغاء </v-btn>
                  <v-btn text color="primary" @click="getDates"> موافق </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="info" text @click="searchData"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="info" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-file-search </v-icon>
        </v-btn>
      </template>
      <span>بحث</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    doctors: Array,
  },
  data: () => ({
    show: false,
    overlay: false,
    dates: [],
    datePickerSearchModel: false,
    doctorId: null,
  }),
  created() {
    console.log(this.doctors);
  },
  methods: {
    getDates() {
      this.datePickerSearchModel = false;
    },
    searchData() {
      let self = this;

      console.log(self.dates);

      let query = "data=1";
      if (self.dates.length > 0) {
        query += `&dates=${JSON.stringify(self.dates)}`;
      }
      if (self.doctorId) {
        query += `&doctorId=${self.doctorId}`;
      }
      self.$http
        .get(`${self.$store.state.apiUrl}/moneyIncomesDate?${query}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.show = false;
          this.$emit("searchData", { data: res.data });
        });
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.length > 0 ? this.dates.join(" - ") : "تاريخ من - الى";
    },
  },
};
</script>

<style lang="scss" scoped></style>
