<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        المصروفات
        <v-spacer></v-spacer>
        <v-text-field rounded v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        <div style="width: 20px"></div>
        <AddTrainingExpenses @reloadTask="initialData()" :employees="employees" :trainingCenterId="trainingCenter" />
      </v-card-title>
      <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <EditTrainingExpenses :student="item" style="display: inline-block" @reloadTask="initialData()" v-if="$store.state.userData.roleId == 1" />
          <span style="width: 20px; display: inline-block"></span>
          <DeleteStudentPayment :dataToDelete="item" @reloadTask="initialData()" style="display: inline-block" v-if="$store.state.userData.roleId == 1" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import SearchStudentCourse from "./studentPayment/SearchStudentCourse.vue";
import AddTrainingExpenses from "./trainingExpenses/AddTrainingExpenses.vue";
import DeleteStudentPayment from "./trainingExpenses/DeleteTrainingExpenses.vue";
import EditTrainingExpenses from "./trainingExpenses/EditTrainingExpenses.vue";
export default {
  components: {
    SearchStudentCourse,
    AddTrainingExpenses,
    DeleteStudentPayment,
    EditTrainingExpenses,
  },
  props: {
    trainingCenter: Number,
  },
  data: () => ({
    overlay: false,
    search: "",
    showAddPayment: false,
    studentId: "",
    courseId: "",
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "تاريخ الصرف", value: "createdAtFormat" },
      { text: "المبلغ", value: "amount" },
      { text: "بواسطة", value: "userName" },
      { text: "الملاحظات", value: "notes" },
      { text: "الاجراءات", value: "actions" },
    ],
    currencies: [],
    expenses: [],
    courses: [],
    employees: [],
    studentPayments: [],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;
      self.showAddPayment = false;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/allTrainingExpensesTraining/${self.trainingCenter}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/courseTrainingCenter/${self.trainingCenter}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/employees`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((expenses, courses, employees) => {
            self.expenses = expenses.data;
            self.courses = courses.data;
            self.employees = employees.data;

            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    allStudentPayments() {
      let self = this;
      self.$http
        .get(`${self.$store.state.apiUrl}/studentPaymentsForCourse?studentId=${self.studentId}&courseId=${self.courseId}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.studentPayments = res.data;
        });
    },
    searchPayment(value) {
      let self = this;
      console.log(value);
      self.studentPayments = value.data;
      self.studentId = value.studentId;
      self.courseId = value.courseId;
      self.showAddPayment = true;
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.expenses.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.notes.toLowerCase().includes(v));
        });
      } else {
        return this.expenses;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
