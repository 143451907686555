<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important" v-if="!overlay">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        التقارير
        <v-spacer></v-spacer>

        <div style="width: 20px"></div>
        <SearchIncome @searchData="onSearch" :doctors="doctors" />
        <div style="width: 20px"></div>
        <v-btn class="mx-2" fab small outlined color="dark" v-if="showTotal" @click="printReport">
          <v-icon dark> mdi-printer </v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table :headers="headers" :items="items" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <template v-slot:[`item.amount`]="{ item }">
          <h4 style="padding: 0 3px">
            {{
              item.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h4>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="printVoucher(item)">
            <v-icon color="primary"> mdi-printer </v-icon>
          </v-btn>

          <!-- <DeleteIncome @reloadTask="initialData" :dataToDelete="item" v-if="$store.state.userData.roleId == 1" style="display: inline-block" /> -->
        </template>
      </v-data-table>
      <div v-if="showTotal" style="padding: 20px">
        المجموع :
        {{
          items
            .reduce((n, { amount }) => n + amount, 0)
            .toLocaleString("en-US", {
              style: "currency",
              currency: "IQD",
            })
        }}
      </div>
    </v-card>
  </div>
</template>

<script>
import SearchIncome from "./income/SearchIncome.vue";
export default {
  components: {
    SearchIncome,
  },
  data: () => ({
    overlay: true,
    headers: [
      { text: "العدد", value: "index" },
      { text: "رقم الوصل", value: "idMoneyIncome" },
      { text: "اسم المراجع", value: "patientName" },
      { text: "اسم المادة", value: "materialName" },
      { text: "اسم الطبيب", value: "userName" },
      { text: "المبلغ", value: "amount" },
      { text: "الملاحظات", value: "notes" },
      { text: "المحاسب", value: "createdName" },
    ],
    items: [],
    doctors: [],
    showTotal: false,
  }),
  created() {
    this.initialization();
  },
  methods: {
    onSearch(value) {
      let self = this;
      console.log(value);
      self.items = value.data;
      self.showTotal = true;
    },
    initialization() {
      let self = this;

      self.overlay = true;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/doctors`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((doctors) => {
            self.doctors = doctors.data;
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    printReport() {
      let self = this;
      if (self.items.length > 0) {
        const WinPrint = window.open("", "", "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0");
        WinPrint.document.write(`<!DOCTYPE html>
        <!DOCTYPE html>
          
        <html lang="ar">
        <style>
         
        @media print {
          @page { margin: 0.5cm !important; }
          
          .pagebreak {
            clear: both;page-break-after: always;
          }
          table { page-break-inside:auto }
          tr    { page-break-inside:avoid; page-break-after:auto }
        }
          body {
            direction : rtl !important;
          }
          table {
            width : 98% !important;
          }
          tr,tr td,th{
            text-align : center !important;
            font-size : 12px !important;
            padding : 5px !important;
          }
            table {
            border-collapse: collapse;
            width: 100%;
            }

            th, td {
            text-align: left;
            padding: 8px;
            }

            tr:nth-child(even) {
            background-color: #D6EEEE;
            }
        </style>
        <body>
            <table border='0' style="padding:3px;margin: auto !important;">
            <tr>
                <th>التسلسل</th>
                <th>رقم الوصل</th>
                <th>اسم المراجع</th>
                <th>اسم المادة</th>
                <th>اسم الطبيب</th>
                <th>المبلغ</th>
                <th colspan="4">الملاحظات</th>
                <th>المحاسب</th>
              </tr>
              
               ${self.rowTable(self.items)}
          </table>
          <p>المجموع : ${self.items
            .reduce((n, { amount }) => n + amount, 0)
            .toLocaleString("en-US", {
              style: "currency",
              currency: "IQD",
            })}</p>
        </body>`);

        WinPrint.focus();
        setTimeout(() => {
          WinPrint.print();
          WinPrint.close();
        }, 1000);
      } else {
        this.$toasted.error("لاتوجد بيانات لطباعتها");
      }
    },
    printTableData() {
      let self = this;
      let tableData = "";

      for (let i = 0; i < self.items.length; i++) {
        tableData += `
              ${self.rowTable(self.items[i])}
            ${i == self.items.length - 1 ? "" : '<div style=" clear: both;page-break-after: always;"></div>'}
          `;
      }
      return tableData;
    },
    rowTable(arrayOfData) {
      let self = this;
      let tdData = "";
      for (let j = 0; j < arrayOfData.length; j++) {
        self.voucherIndex += 1;
        tdData += `
              <tr>
                <td>${j + 1}</td>
                <td>${arrayOfData[j].idMoneyIncome}</td>
                <td>${arrayOfData[j].patientName}</td>
                <td>${arrayOfData[j].materialName}</td>
                <td>${arrayOfData[j].userName}</td>
                
                <td style="font-size:12px">${arrayOfData[j].amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })}</td>
                <td  colspan="4"> ${arrayOfData[j].notes}</td>
                <td>${arrayOfData[j].createdName}</td>
              </tr>
            
          `;
      }
      return tdData;
    },
  },
};
</script>

<style lang="scss" scoped></style>
